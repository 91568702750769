import axios from 'axios';
import { createStore } from 'vuex';
import router from '@/router';

export default createStore({
    state: {
        token: false,
        isAdmin: false,
        user_data: {}
    },
    getters: {
        token(state){
            return state.token;
        },
        isLogged(state){
            return state.token != false;
        },
        isAdmin(state){
            return state.token && state.isAdmin;
        },
        userData(state){
            return state.user_data;
        }
    },
    mutations: {
        login: (state, obj) => {
            localStorage.setItem('token', obj.token);
            localStorage.setItem('finish_date', obj.date);
            localStorage.setItem('username', obj.username);
            localStorage.setItem('group_name', obj.group_name);
            if(obj.is_admin){
                localStorage.setItem('isAdmin', true);
                state.isAdmin = true;
            }else{
                state.isAdmin = false;
            }
            state.user_data = {username: obj.username, group_name: obj.group_name};
            let fin = obj.date - Math.round(new Date().getTime() / 1000);
            setTimeout(()=>{
                this.commit("logout");
            }, fin * 1000);
            state.token = obj.token;
        },
        logout: (state) => {
            localStorage.removeItem('token');
            localStorage.removeItem("finish_date");
            localStorage.removeItem("username");
            localStorage.removeItem("group_name");
            localStorage.removeItem("isAdmin");
            axios.post(process.env.VUE_APP_URL+`logout`,{} ,{headers: {"API-TOKEN": state.token}});
            state.token = false;
            state.isAdmin = false;
            state.user_data = {};
            router.push("/login");
        },
        initialStore(state){
            if(localStorage.getItem('token')){
                if(localStorage.getItem('isAdmin')){
                    state.isAdmin = localStorage.getItem('isAdmin');
                }
                let fin = localStorage.getItem('finish_date') - Math.round(new Date().getTime() / 1000);
                if(fin < 10){
                    this.commit("logout");
                    return;
                }
                state.token = localStorage.getItem('token');
                state.user_data = {username: localStorage.getItem('username'), group_name: localStorage.getItem('group_name')};
                setTimeout(()=>{
                    this.commit("logout");
                }, fin * 1000);
            }
        },
    },
    actions: {
    },
    modules: {
    }
});