import { createRouter, createWebHistory } from 'vue-router'
const routes = [
    {
        path: "/",
        redirect: { path: "/login" }
    },
    {
        path: '/login',
        name: 'LoginView',
        component: () => import(/* webpackChunkName: "LoginView" */ '../views/LoginView.vue')
    },
    {
        path: '/register',
        name: 'RegisterView',
        component: () => import(/* webpackChunkName: "RegisterView" */ '../views/RegisterView.vue')
    },
    {
        path: '/verificar/:code',
        name: 'VerifyView',
        component: () => import(/* webpackChunkName: "VerifyView" */ '../views/VerifyView.vue')
    },
    {
        path: '/reset_password/:code',
        name: 'ResetPassword',
        props: { reset_type: 2},
        component: () => import(/* webpackChunkName: "ResetPassword" */ '../views/ResetPassword.vue')
    },
    {
        path: '/request_reset_password',
        name: 'RequestResetPassword',
        props: { reset_type: 1},
        component: () => import(/* webpackChunkName: "RequestResetPassword" */ '../views/ResetPassword.vue')
    },
    {
        path: '/tipospuntos',
        name: 'typePoint',
        component: () => import(/* webpackChunkName: "landing" */ '../views/TypePoint.vue')
    },
    {
        path: '/puntos/:urlpoint?',
        name: 'nompoints',
        component: () => import(/* webpackChunkName: "landing" */ '../views/NomPoint.vue')
    },
    {
        path: "/micuenta",
        name: 'micuenta',
        component: () => import(/* webpackChunkName: "micuenta" */ '../views/MyAccount.vue')
    },
    {
        path: "/groups",
        name: 'groups',
        component: () => import(/* webpackChunkName: "groups" */ '../views/GroupView.vue')
    },
    {
        path: "/admins",
        name: 'admins',
        component: () => import(/* webpackChunkName: "admins" */ '../views/AdminView.vue')
    },
    {
        path: "/:pathMatch(.*)*",
        name: '404',
        component: () => import(/* webpackChunkName: "404" */ '../views/404View.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
