<template>
  <div>
    <MyHeader v-if="store.getters.isLogged"/>
    <transition name="fade-message">
      <MyMensaje :msg_pet="msg_pet"/>
    </transition>
    <router-view v-slot="{ Component, route }">
      <component :is="Component" :key="route.name" />
    </router-view>
  </div>
</template>

<script>
    import { inject } from 'vue';
    import MyHeader from '@/components/MyHeader.vue';
    import MyMensaje from '@/components/MyMensaje.vue';
    import { useStore } from 'vuex';
    export default {
        name: 'App',
        components: {
            MyHeader,
            MyMensaje
        },
        data: function(){
            return{
                msg_pet: {},
                store: useStore()
            }
        },
        mounted() {
            const emitter = inject("emitter");
            emitter.on("showmsg", (m) => { this.msg_pet=m; });
        }
    }
</script>
