<template>
    <div class="message_fixed" v-if="open">
        <div class="message" :class="msg_pet.clase">
            <h3>{{msg_pet.msg}}</h3>
        </div>
    </div>
</template>

<script setup>
    import { watch, ref} from 'vue';
    const props = defineProps({
        msg_pet: {
            type: Object
        }
    });
    const open = ref(false);
    watch(() => props.msg_pet, (n) => {
        if(n){
            setTimeout(()=>{ open.value=false; }, props.msg_pet.timeout ? props.msg_pet.timeout : 6000);
            open.value=true;
        }
    });
</script>

<style scoped>
    .message_fixed {padding: 0;position: fixed;top: 0;left: 0;right: 0;z-index: 9999;
        display: flex;
        justify-content: center;
        -webkit-transition: all 0.3s ease-in-out;  
        -moz-transition: all 0.3s ease-in-out; 
        -o-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out; 
        transition: all 0.3s ease-in-out;
    }
    .message_fixed.visible {top: 20px;}
    .message_fixed .message {font-weight: 400;max-width: 920px;z-index: 99999;overflow: hidden;padding: 10px 20px;
        -webkit-border-top-right-radius: 0;
        -webkit-border-top-left-radius: 0;
        -moz-border-radius-topright: 0;
        -moz-border-radius-topleft: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        -webkit-border-bottom-right-radius: 7px;
        -webkit-border-bottom-left-radius: 7px;
        -moz-border-radius-bottomright: 7px;
        -moz-border-radius-bottomleft: 7px;
        border-bottom-right-radius: 7px;
        border-bottom-left-radius: 7px;
        -webkit-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.4);
        -moz-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.4);
        box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.4);
    }
    .message_fixed .message h3 {font-size: 18px;font-weight: 700;margin: 0;text-align: center;}
    .message_fixed .message.ok {background: #06c18a;}
    .message_fixed .message.ok h3 {color: #fff;}
    .message_fixed .message.ok h3 span svg {fill: #fff;}
    .message_fixed .message.ok h3 i {fill: #fff;}
    .message_fixed .message.ko {background: #ff5050;}
    .message_fixed .message.ko h3 {color: #fff;}
    .message_fixed .message.ko h3 span svg {fill: #fff;}
    .message_fixed .message.ko h3 i {fill: #fff;}
    .message_fixed .message.info {background: #82d0ff;}
    .message_fixed .message.info h3 {color: #fff;}
    .message_fixed .message.info h3 span svg {fill: #b3e1fd;}
    .message_fixed .message.info h3 i {fill: #fff;}
</style>
