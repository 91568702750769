import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
// import mitt from 'mitt';
// import '@/composables/clickOutside.js';
import "@/assets/style.css";
import mitt from 'mitt';  
const emitter = mitt();

const app = createApp({extends: App, beforeCreate() { this.$store.commit('initialStore'); }});

const clickOutside = {
    beforeMount: (el, binding) => {
      el.clickOutsideEvent = event => {
        // here I check that click was outside the el and his children
        if (!(el == event.target || el.contains(event.target))) {
          // and if it did, call method provided in attribute value
          binding.value();
        }
      };
      document.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted: el => {
      document.removeEventListener("click", el.clickOutsideEvent);
    },
  };


app.use(router).use(store).provide('emitter', emitter).directive("click-outside", clickOutside).mount('#app');



axios.defaults.withCredentials = true;

axios.interceptors.response.use(
    response => response,
    (error) =>  {
        let res = {data: false};
        if( ! error.response.status)
            return res;
        if (error.response.status == 404) {
            return false;
        }else if (error.response.status == 401) {
            store.commit('logout');
            router.push("/login");
        }
        return res;
    }
);

